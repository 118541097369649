<template>
  <div class="interview" ref="interview">
    <!--  -->
    <van-nav-bar
      :title="$t('reqrcode.qrTxt7')"
      left-text
      left-arrow
      @click-left="onClickLeft"
      :fixed="true"
      :border="false"
    />
    <div class="fixedH" style="height: 1.22667rem;"></div>
    <div class="content">
      <div ref="QRcodeSrcImg" class="QRcodeSrcImg">
        <div class="ewm">
          <qrcode :text="QRCode" :width="300" :height="300"/>
          <!-- <p class="p">{{ $t("home.text7") }}</p> -->
          <p class="p">
            <!-- 长按二维码识别进行简历投递 -->
          </p>
        </div>
        <van-cell-group :border="false">
          <van-cell :title="$t('reqrcode.qrTxt8')" :value="userInfo.empcode" />
          <van-cell :title="$t('reqrcode.qrTxt9')" :value="userInfo.empname" />
        </van-cell-group>
      </div>
    </div>
    <!-- 此处是储存截取后的图片 -->
    <div class="photoShow" v-if="photoShow">
      <div class="photo">
        <div class="close">
          <!-- 长按图片保存 -->
          <!-- 请长按二维码进行转发 -->
          <span></span>
          <van-icon name="cross" @click="closePic" />
        </div>
        <img :src="photoUrl" id="img" alt />
        <!-- <span>长按图片保存和分享</span> -->
        <div class="tip">{{ $t('reqrcode.qrTxt6') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import qrcode from '@components/qrcode'
import { getEmpInfoByEmpCode, saveBarCodeRecord } from '@api/wx.js'
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
import request from '@/utils/request2'
export default {
  components: {
    qrcode
  },
  data() {
    return {
      userInfo,
      outtime: window.eastriver_barcode_outtime
        ? window.eastriver_barcode_outtime
        : 120,
      recommendUrl: window.eastriver_barcode_url
        ? window.eastriver_barcode_url
        : 'https://hyclound.cn:8019/EastRiver/QRCode',
      photoShow: false,
      photoUrl: '',
      showBtn: true,
      QRCode: '',
      idno: '',
      interval: null
    }
  },
  created() {
    getEmpInfoByEmpCode({
      empcode: this.userInfo.empcode
    }).then(res => {
      this.idno = res.data[0].idno
      this.b4getQRCode()
      this.interval = setInterval(() => {
        this.b4getQRCode()
      }, 120 * 1000)
    })
  },
  methods: {
    onClickLeft() {
      this.$router.push('/home')
    },
    b4getQRCode() {
      saveBarCodeRecord({
        empid: this.userInfo.empid,
        outtime: this.outtime
      }).then(res => {
        this.getQRCode()
      })
    },
    getQRCode() {
      // let res = request({
      //   url: this.recommendUrl,
      //   method: 'get',
      //   params: { CardSn: this.idno, DesKey: 12345678, Des: 0 }
      // })
      // 创建XML请求
      var ajaxObj = new XMLHttpRequest()
      // 以get形式进行发送请求
      ajaxObj.open(
        'get',
        this.recommendUrl +
          '?CardSn=' +
          this.idno +
          '&DesKey=12345678&Des=0&outtime=' +
          this.outtime
      )
      // 请求结束断开
      ajaxObj.send()
      // 初始化变量（必要）
      // 转入： this转为function（）方法内部函数（重点）
      const that = this
      ajaxObj.onreadystatechange = function() {
        if (ajaxObj.readyState === 4 && ajaxObj.status === 200) {
          // 请求放回的结果以文本形式，使用JSON.parse()方法进行转为json形式
          var data = JSON.parse(ajaxObj.responseText)
          console.log('数据返回成功', ajaxObj.responseText)
          if (data.error == '0') {
            that.QRCode = data.QRCode
          } else {
            Notify({ type: 'danger', message: data.msg })
          }
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.interview {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;
    .van-hairline--bottom::after {
      border-bottom-width: 0;
    }
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    /deep/.van-nav-bar__text {
      color: #fff;
      font-size: 24px;
      font-family: Source Han Sans CN;
    }
  }
  .van-dialog {
    /deep/.van-dialog__content {
      position: relative;
      height: 100%;
      text-align: center;
      .ewm {
        width: 4.52rem;
        margin: 0 auto;
        padding-top: 1rem;
        min-height: 5.52rem;

        img {
          width: 4.52rem;
          height: 4.52rem;
        }
      }
      p {
        width: 100%;
        margin-top: 0.5rem;
      }
      .van-icon {
        width: 28px;
        height: 28px;
        position: absolute;
        right: 40px;
        top: 32px;
      }
    }
  }
  .content {
    height: calc(100% - 92px);
    overflow: auto;
    background: #2b8df0;
    .QRcodeSrcImg {
      background: #2b8df0;
      padding: 30px 0;
      .ewm {
        background: #fff;
        margin: 0 auto;
        width: 80%;
        border-radius: 15px;
        padding: 30px 0 1px;
        img {
          width: 4.52rem;
          height: 4.52rem;
        }
        .p {
          font-size: 36px;
          width: 100%;
          margin-top: 0.5rem;
          text-align: center;
        }
      }
      .van-cell-group {
        margin: 30px auto 0;
        background: #fff;
        width: 80%;
        border-radius: 15px;
        padding: 20px;
      }
    }

    .foot {
      text-align: center;
      margin: 30px auto 0;
      width: 80%;
      .btn {
        border-radius: 15px;
        width: 100%;
        .van-button__content {
          .van-button__text {
            font-size: 36px;
            font-weight: 500;
          }
        }
      }
    }
  }
  .photoShow {
    background: rgba(0, 0, 0, 0.616);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .photo {
      //保存图片容器大小
      width: 80%;
      color: #fff;
      img {
        display: block;
        width: 100%;
        border-radius: 15px;
      }
      .close {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 36px;
      }
      .tip {
        text-align: center;
        color: #ffe91e;
        font-size: 48px;
        position: absolute;
        bottom: 30%;
        left: 50%;
        transform: translateX(-50%);
        width: 80%;
      }
    }
  }
}
</style>
